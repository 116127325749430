.Footer {
  width: 100%;
  grid-area: footer;
  display: grid;
  align-items: center;
  font-size: .75em;
  min-height: 130px;
}

.Footer p{
  margin: 10px;
  padding: 15px;
  color: black;
}