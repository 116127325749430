@import url(https://fonts.googleapis.com/css?family=Lekton);
@font-face {
  font-family: 'whitney_book';
  src: local('whitney_book'), url(/static/media/whitney_book.f07b6a59.ttf) format('truetype');
}


@font-face {
  font-family: 'whitney_bold';
  src: local('whitney_bold'), url(/static/media/whitney_bold.2231620f.ttf) format('truetype');
}


body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'whitney_book', sans-serif !important;
}

.Grid-container {
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
}

.Grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  padding-top: 100px;
  margin-left: 25px;
  margin-right: auto;
  width: calc(100% - 125px);
}

.Grid-cell {
  position: relative;
  margin-bottom: 25px;
}

.Grid-cell img {
  display: block;
  opacity: 1;
  width: 100%;
}

.Grid-mask {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.1;
}

.Grid-cell p {
  color: white;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 15px;
  text-transform: uppercase;
  text-align: right;
  font-size: 1em;
}


.cross:before, .cross:after {
  content: "";
  position: absolute;
  z-index: 4;
  background: white;
}

.cross:before {
    left: 50%;
    width: 1%;
    margin-left: -.5%;
    height: 100%;
}

.cross:after {
  top: 50%;
  height: 1%;
  margin-top: -.5%;
  width: 100%;
}



@media screen and (min-width: 600px) {
  .Grid-cell {
    margin: 1rem;
    width: calc(50% - 2rem);
  }

  .Grid-cell p {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 1000px) {
  
  .Grid-container {
    width: 100%;
    background-color: white;
  }
  
  .Grid {
    margin-left: 25px;
    margin-right: auto;
    width: calc(100% - 125px);
  }

  .Grid-cell {
    margin: 1rem;
    width: calc(100% / 4 - 2rem);
  }

  .Grid-cell img {
    opacity: 1;
    display: block;
    width: 100%;
    transition: .5s;
  }
  .Grid-cell p {
    color: black;
    font-size: 1em;
    opacity: 0;
    transition: .5s;
  }

  .Grid-mask {
    display: none;
  }

  .Grid-cell:hover img {
    opacity: 0.1;
  }

  .Grid-cell:hover p {
    opacity: 1;
  }
}



.Logo {
  z-index: 10;
}

.Logo:hover .garments {
    fill-opacity: 0;
}

.Hamburger-hamburger {
  width: 35px;
  height: 4px;
  background: #000;
  position: absolute;
  transform: translate(8px, 22px);
  box-shadow: 0 2px 5px rgba(0,0,0,.2);
  transition: .5s;
}

.Hamburger-hamburger:before,
.Hamburger-hamburger:after {
  content: '';
  position: absolute;
  width: 35px;
  height: 4px;
  background: #000;
  box-shadow: 0 2px 5px rgba(0,0,0,.2); 
  transition: .5s;
}

.Hamburger-hamburger:before {
  top: -9px;
}

.Hamburger-hamburger:after {
  top: 9px;
}

.Hamburger.active .Hamburger-hamburger {
  background: rgba(0,0,0,0);
  box-shadow: 0 2px 5px rgba(0,0,0,0);
}

.Hamburger.active .Hamburger-hamburger:before {
  transform: rotate(45deg);
  background: black;
  top: 0;
}

.Hamburger.active .Hamburger-hamburger:after {
  transform: rotate(135deg);
  background: black;
  top: 0;
}

.Hamburger.hide {
  display: none;
}


.Hero {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: -1;
}

.Hero p {
  position: fixed;
  height: 10px;
  top: 50px;
  left: 50px;
  margin-top: -5px;
  color: white;
  z-index: 0;
}

.Hero svg {
  position: fixed;
  height: 150px;
  bottom: 50px;
  right: 50px;
  z-index: 0;
}

.Hero iframe {
   width: 100vw;
   height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
   min-height: 100vh;
   min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}


@media screen and (min-width: 1000px) {

.Hero svg {
  height: 200px;
  bottom: 100px;
  right: 100px;
}

.Hero p {
  top: 100px;
  left: 100px;
}
}






.Language button {
  font-size: 1em;
  font-family: 'whitney_book';
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
}
.Language span {
  padding: 2px;
}
.Language.hide {
  display: none;
}

.Lost {
  height: calc(100vh - 200px);
}
.Menu {
  position: fixed;
  right: 0;
  top:0;
  bottom:0;
  background: white;
  transition: .5s;
  width: 100vw;
  transform:translateX(100vw);
  box-sizing: border-box;
  z-index: 5;
}

.Menu.active {
  transform: translateX(0);
}

.Menu-item {
  margin: 5px;
  margin-right: 32.5px;
  padding: 0px;
  font-size: 1.5em;
  font-family: 'whitney_book';
  text-transform: uppercase;
  text-align: right; 
  text-decoration: none;
  color: black;
  cursor: pointer;
  font-weight: bold;
}

.Menu-item.inactive {
  cursor: default;
}

.Menu-item img {
  transform: translateY(4px);
  width: 25px;
}

.Menu-item svg {
  visibility: hidden;
  padding-right: 10px;
  width: 25px;
}

.White {
  grid-area: white;
  height: 300px;
}

.Aside {
    margin-top: 20vh;
    display: grid;
    align-content: center;
}

@media screen and (min-width: 600px) {
  .Menu {
    width: 50vw;
    transform:translateX(50vw);
    border-left: 3px solid black;
  }
}

@media screen and (min-width: 1000px) {
  .Menu {
    width: 30vw;
    transform:translateX(30vw);
    border-left: 3px solid black;
  }
  .Menu-item:hover svg{
    visibility: visible;
  }
}



.Footer {
  width: 100%;
  grid-area: footer;
  display: grid;
  align-items: center;
  font-size: .75em;
  min-height: 130px;
}

.Footer p{
  margin: 10px;
  padding: 15px;
  color: black;
}
.Lines {
  fill: black;
}
.RenderHelper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;  
  grid-template-areas: "gallery" "gallery"
                       "text" "text";
}

.RenderHelper-container {
  grid-area: gallery;
  padding-top: 100px;
}

.RenderHelper-container div, .RenderHelper-container {
  max-width: 100%;
  position: relative;
}

.RenderHelper-image {
    width: 100%;
}

.RenderHelper-navigation {
  color: #ddd;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  background-color: rgba(255,255,255,.3);
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline:none;
  z-index: 4;
}
.hide {
    display: none;
}

.RenderHelper-content {
    position: absolute
}

.RenderHelper-content-wrapper {
    width: 100%;
}

.RenderHelper-navigation:hover{
  background-color: rgba(255,255,255,.6);
}

.RenderHelper-left {
  left: 10px;
}
.RenderHelper-right {
  right: 10px;
}

.right {
  transform: translate(-1px, 0) rotate(-45deg);
  -webkit-transform: translate(-1px, 0) rotate(-45deg);
}

.left {
  transform: translate(1px, 0) rotate(135deg) ;
  -webkit-transform: translate(1px, 0) rotate(135deg) ;
}

i {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  
}

.Navigate {
    display: none;
}

@media screen and (min-width: 1000px) {
  .RenderHelper-navigation {
    visibility: hidden;
  }
  .RenderHelper-container {
    display: grid;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;

  }

  .RenderHelper-content {
    max-width: 80%;
  }

  .Navigate {
    position: absolute;
    width: 55%;
    height: 100%;
    display: block;
  }

  .Navigate.navright {
    right: -5%;
  }

  .Navigate.navleft {
    left: -5%;   
  }

  .Navigate.decorationright{
    cursor: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='32px' height='32px' viewBox='0 0 2048 2048' style='enable-background:new 0 0 2048 2048;' xml:space='preserve'%3E %3Cpath d='M1299 1088q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23z'/%3E %3C/svg%3E"), pointer;
  }

  .Navigate.decorationleft{
    transform: rotate(180deg);
    cursor: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='32px' height='32px' viewBox='0 0 2048 2048' style='transform:rotate(180deg);enable-background:new 0 0 2048 2048;' xml:space='preserve'%3E %3Cpath d='M1299 1088q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23z'/%3E %3C/svg%3E"), pointer;
  }

  .RenderHelper {
    height: 100vh;
    grid-template-columns: 70% 30%;
    grid-template-rows: unset;
    grid-template-areas:  "gallery text"
                          "gallery text";
  }

  .RenderHelper-image .RenderHelper-container img, .RenderHelper-container {
    
  }

  .RenderHelper-image {
    width: 100%;
    height: auto;
  }

  .RenderHelper-content.vertical {
    max-width: 70%;
  }
}


.Card {
  grid-area: text;
  background-color: white;
  padding: 10px 25px 110px 25px;
  overflow: scroll;
}

.Card p {
  text-align: justify;
}

.Card-description {

}

.Shortcut-thumb {
  border: 0;
  padding: 0;
  margin-left: 10px;
  outline: none;
  font-family: inherit;
  height: 7px;
  width: 7px;
  text-align: center;
  background-color: #ccc; 
  border-radius: 50%; 
}

.Shortcut-thumb.highlight {
  background-color: black;
  height: 8px;
  width: 8px;
}

.Shortcut-thumb span {
  display: none;
}

.Card-title {
  font-size: 2.1em;
  margin-bottom:1em;
  text-transform: uppercase;
}

.Card-subtitle {
  text-transform: uppercase;
  margin:0;
  font-size: 1em;
}

.Card-text {
  font-size: 1em;
  margin-top: 1em;
  margin-bottom: 0;
}

.Card-footer {
    margin-top: 5em;
}

.Card-footer p {
  font-style: oblique;
  font-size: .6em;
  text-align: left;
  margin-top: .3em;
}

.Card-colabs h2{
    font-weight: bold;
    font-size: 1.25em;
}

  .Card-contact {
    margin-top: 5em;
  }

.Card-colabs p {
    font-size: 1em;
    margin-top: .5em;
}

.Card-contact p {
    font-size: .75em;
    margin-top: .5em;
}


.Card-text:last-child {
  margin-bottom:2em;
}

@media screen and (min-width: 600px) {

  .Shortcut {
    display: inline;
  }

}

@media screen and (min-width: 1000px) {

  .Shortcut {
    display: inline;
  }

  .Card-contact {
    margin-top: 25em;
  }

  .Card {
    padding: 100px 50px;
  }

  .Shortcut-thumb {
    font-size: 13px;
    height: 35px;
    width: 35px;
    background-color: white;
  }
  
  .Shortcut-thumb.highlight {
    border-width: 3px;
    border-style: solid;
    background-color: white;
    height: 35px;
    width: 35px;
  }

  .Shortcut-thumb span {
    display: inline;
  }
}
.Us {
    height: 90%;
}


@media screen and (min-width: 1000px) {
  .Us {
    position: relative;
  }
  .Us-image-container{
    width: 50%;
    float: left;
  }
  .Us-image-container img{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .Us-content h2{
    width: 50%;
    float: right;
  }

  .Us-colabs h2, p {
    font-size: 2em;
  }
  .Us-litany {
    margin-top: 50px;
  }
  .Us-litany p{

    padding-top: 0;
    margin: 0;
    font-style: oblique;
  }
}
.Body {
  display: grid;
  box-sizing: border-box;
  overflow-x: hidden;
}

.EasterEgg {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
}

.Content {
}

.Header {
  grid-area: header;
}
.Content {
  grid-area: main;
}

.Body.active {
  overflow: hidden;
  position: fixed;
}

.App-cloud {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  opacity: 0.5;
  display: none;
}

.App-controls {
    width: 100%;
    margin: 0px;
    height: 50px;
    display: inline;
    position: fixed;
    top: 0px;
    z-index:10;
}

.App-controls-separator {
    font-size: 1em;
    float: right;
    font-weight: bold;
    margin-right: 15px;
    margin-top: 40px;
}

.solid-background {
   background-color: white;
}

.App-controls li{
    display: inline;
}

.App-projects {
    float: right;
    padding: 0;
    font-size: 1em;
    font-family: 'whitney_book';
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    cursor: pointer;
    margin-right: 15px;
    margin-top: 40px;
}

.App-projects span {
    
}

.App-projects:hover svg{
    visibility: visible;
}

.App-projects.inactive {
  cursor: default;
}

.App-projects img {
  width: 25px;
}

.App-projects svg {
    display: inline;
    visibility: hidden;
    padding-right: 10px;
    width: 15px;
}

.Language {
    float: right;
    margin-right: 15px;
    margin-top: 40px;
}

.Hamburger {
  margin-right: 25px;
  margin-top: 25px;
  float: right;
  width: 50px;
  height: 50px;
  cursor: pointer;
}


.App-cloud.active {
  display: initial;
}

@media screen and (min-width: 600px) {
  .Body.active {
    overflow: scroll;
    position: relative;
  }
}

@media screen and (min-width: 1000px) {
  
}



.guide {
  width: calc(100% - 50px) !important;
  height: calc(100% - 50px) !important;
  position: fixed;
  left: 25px;
  top: 25px;
  background-color: black;
  border: red;
  opacity: .8;
  z-index: 100;
  display: none;
}






