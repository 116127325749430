.Card {
  grid-area: text;
  background-color: white;
  padding: 10px 25px 110px 25px;
  overflow: scroll;
}

.Card p {
  text-align: justify;
}

.Card-description {

}

.Shortcut-thumb {
  border: 0;
  padding: 0;
  margin-left: 10px;
  outline: none;
  font-family: inherit;
  height: 7px;
  width: 7px;
  text-align: center;
  background-color: #ccc;
  -webkit-border-radius: 50%; 
  -moz-border-radius: 50%; 
  border-radius: 50%; 
}

.Shortcut-thumb.highlight {
  background-color: black;
  height: 8px;
  width: 8px;
}

.Shortcut-thumb span {
  display: none;
}

.Card-title {
  font-size: 2.1em;
  margin-bottom:1em;
  text-transform: uppercase;
}

.Card-subtitle {
  text-transform: uppercase;
  margin:0;
  font-size: 1em;
}

.Card-text {
  font-size: 1em;
  margin-top: 1em;
  margin-bottom: 0;
}

.Card-footer {
    margin-top: 5em;
}

.Card-footer p {
  font-style: oblique;
  font-size: .6em;
  text-align: left;
  margin-top: .3em;
}

.Card-colabs h2{
    font-weight: bold;
    font-size: 1.25em;
}

  .Card-contact {
    margin-top: 5em;
  }

.Card-colabs p {
    font-size: 1em;
    margin-top: .5em;
}

.Card-contact p {
    font-size: .75em;
    margin-top: .5em;
}


.Card-text:last-child {
  margin-bottom:2em;
}

@media screen and (min-width: 600px) {

  .Shortcut {
    display: inline;
  }

}

@media screen and (min-width: 1000px) {

  .Shortcut {
    display: inline;
  }

  .Card-contact {
    margin-top: 25em;
  }

  .Card {
    padding: 100px 50px;
  }

  .Shortcut-thumb {
    font-size: 13px;
    height: 35px;
    width: 35px;
    background-color: white;
  }
  
  .Shortcut-thumb.highlight {
    border-width: 3px;
    border-style: solid;
    background-color: white;
    height: 35px;
    width: 35px;
  }

  .Shortcut-thumb span {
    display: inline;
  }
}