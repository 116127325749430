.Grid-container {
  width: 100%;
  background-color: white;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
}

.Grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  padding-top: 100px;
  margin-left: 25px;
  margin-right: auto;
  width: calc(100% - 125px);
}

.Grid-cell {
  position: relative;
  margin-bottom: 25px;
}

.Grid-cell img {
  display: block;
  opacity: 1;
  width: 100%;
}

.Grid-mask {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.1;
}

.Grid-cell p {
  color: white;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 15px;
  text-transform: uppercase;
  text-align: right;
  font-size: 1em;
}


.cross:before, .cross:after {
  content: "";
  position: absolute;
  z-index: 4;
  background: white;
}

.cross:before {
    left: 50%;
    width: 1%;
    margin-left: -.5%;
    height: 100%;
}

.cross:after {
  top: 50%;
  height: 1%;
  margin-top: -.5%;
  width: 100%;
}



@media screen and (min-width: 600px) {
  .Grid-cell {
    margin: 1rem;
    width: calc(50% - 2rem);
  }

  .Grid-cell p {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 1000px) {
  
  .Grid-container {
    width: 100%;
    background-color: white;
  }
  
  .Grid {
    margin-left: 25px;
    margin-right: auto;
    width: calc(100% - 125px);
  }

  .Grid-cell {
    margin: 1rem;
    width: calc(100% / 4 - 2rem);
  }

  .Grid-cell img {
    opacity: 1;
    display: block;
    width: 100%;
    transition: .5s;
  }
  .Grid-cell p {
    color: black;
    font-size: 1em;
    opacity: 0;
    transition: .5s;
  }

  .Grid-mask {
    display: none;
  }

  .Grid-cell:hover img {
    opacity: 0.1;
  }

  .Grid-cell:hover p {
    opacity: 1;
  }
}


