.Menu {
  position: fixed;
  right: 0;
  top:0;
  bottom:0;
  background: white;
  transition: .5s;
  width: 100vw;
  transform:translateX(100vw);
  box-sizing: border-box;
  z-index: 5;
}

.Menu.active {
  transform: translateX(0);
}

.Menu-item {
  margin: 5px;
  margin-right: 32.5px;
  padding: 0px;
  font-size: 1.5em;
  font-family: 'whitney_book';
  text-transform: uppercase;
  text-align: right; 
  text-decoration: none;
  color: black;
  cursor: pointer;
  font-weight: bold;
}

.Menu-item.inactive {
  cursor: default;
}

.Menu-item img {
  transform: translateY(4px);
  width: 25px;
}

.Menu-item svg {
  visibility: hidden;
  padding-right: 10px;
  width: 25px;
}

.White {
  grid-area: white;
  height: 300px;
}

.Aside {
    margin-top: 20vh;
    display: grid;
    align-content: center;
}

@media screen and (min-width: 600px) {
  .Menu {
    width: 50vw;
    transform:translateX(50vw);
    border-left: 3px solid black;
  }
}

@media screen and (min-width: 1000px) {
  .Menu {
    width: 30vw;
    transform:translateX(30vw);
    border-left: 3px solid black;
  }
  .Menu-item:hover svg{
    visibility: visible;
  }
}


