
.Language button {
  font-size: 1em;
  font-family: 'whitney_book';
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
}
.Language span {
  padding: 2px;
}
.Language.hide {
  display: none;
}
