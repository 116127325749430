.Body {
  display: grid;
  box-sizing: border-box;
  overflow-x: hidden;
}

.EasterEgg {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
}

.Content {
}

.Header {
  grid-area: header;
}
.Content {
  grid-area: main;
}

.Body.active {
  overflow: hidden;
  position: fixed;
}

.App-cloud {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  opacity: 0.5;
  display: none;
}

.App-controls {
    width: 100%;
    margin: 0px;
    height: 50px;
    display: inline;
    position: fixed;
    top: 0px;
    z-index:10;
}

.App-controls-separator {
    font-size: 1em;
    float: right;
    font-weight: bold;
    margin-right: 15px;
    margin-top: 40px;
}

.solid-background {
   background-color: white;
}

.App-controls li{
    display: inline;
}

.App-projects {
    float: right;
    padding: 0;
    font-size: 1em;
    font-family: 'whitney_book';
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    cursor: pointer;
    margin-right: 15px;
    margin-top: 40px;
}

.App-projects span {
    
}

.App-projects:hover svg{
    visibility: visible;
}

.App-projects.inactive {
  cursor: default;
}

.App-projects img {
  width: 25px;
}

.App-projects svg {
    display: inline;
    visibility: hidden;
    padding-right: 10px;
    width: 15px;
}

.Language {
    float: right;
    margin-right: 15px;
    margin-top: 40px;
}

.Hamburger {
  margin-right: 25px;
  margin-top: 25px;
  float: right;
  width: 50px;
  height: 50px;
  cursor: pointer;
}


.App-cloud.active {
  display: initial;
}

@media screen and (min-width: 600px) {
  .Body.active {
    overflow: scroll;
    position: relative;
  }
}

@media screen and (min-width: 1000px) {
  
}



.guide {
  width: calc(100% - 50px) !important;
  height: calc(100% - 50px) !important;
  position: fixed;
  left: 25px;
  top: 25px;
  background-color: black;
  border: red;
  opacity: .8;
  z-index: 100;
  display: none;
}





