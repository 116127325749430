
.Hamburger-hamburger {
  width: 35px;
  height: 4px;
  background: #000;
  position: absolute;
  transform: translate(8px, 22px);
  box-shadow: 0 2px 5px rgba(0,0,0,.2);
  transition: .5s;
}

.Hamburger-hamburger:before,
.Hamburger-hamburger:after {
  content: '';
  position: absolute;
  width: 35px;
  height: 4px;
  background: #000;
  box-shadow: 0 2px 5px rgba(0,0,0,.2); 
  transition: .5s;
}

.Hamburger-hamburger:before {
  top: -9px;
}

.Hamburger-hamburger:after {
  top: 9px;
}

.Hamburger.active .Hamburger-hamburger {
  background: rgba(0,0,0,0);
  box-shadow: 0 2px 5px rgba(0,0,0,0);
}

.Hamburger.active .Hamburger-hamburger:before {
  transform: rotate(45deg);
  background: black;
  top: 0;
}

.Hamburger.active .Hamburger-hamburger:after {
  transform: rotate(135deg);
  background: black;
  top: 0;
}

.Hamburger.hide {
  display: none;
}

