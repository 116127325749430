@import "../node_modules/react-image-gallery/styles/css/image-gallery.css";

.RenderHelper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;  
  grid-template-areas: "gallery" "gallery"
                       "text" "text";
}

.RenderHelper-container {
  grid-area: gallery;
  padding-top: 100px;
}

.RenderHelper-container div, .RenderHelper-container {
  max-width: 100%;
  position: relative;
}

.RenderHelper-image {
    width: 100%;
}

.RenderHelper-navigation {
  color: #ddd;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  background-color: rgba(255,255,255,.3);
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline:none;
  z-index: 4;
}
.hide {
    display: none;
}

.RenderHelper-content {
    position: absolute
}

.RenderHelper-content-wrapper {
    width: 100%;
}

.RenderHelper-navigation:hover{
  background-color: rgba(255,255,255,.6);
}

.RenderHelper-left {
  left: 10px;
}
.RenderHelper-right {
  right: 10px;
}

.right {
  transform: translate(-1px, 0) rotate(-45deg);
  -webkit-transform: translate(-1px, 0) rotate(-45deg);
}

.left {
  transform: translate(1px, 0) rotate(135deg) ;
  -webkit-transform: translate(1px, 0) rotate(135deg) ;
}

i {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  
}

.Navigate {
    display: none;
}

@media screen and (min-width: 1000px) {
  .RenderHelper-navigation {
    visibility: hidden;
  }
  .RenderHelper-container {
    display: grid;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;

  }

  .RenderHelper-content {
    max-width: 80%;
  }

  .Navigate {
    position: absolute;
    width: 55%;
    height: 100%;
    display: block;
  }

  .Navigate.navright {
    right: -5%;
  }

  .Navigate.navleft {
    left: -5%;   
  }

  .Navigate.decorationright{
    cursor: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='32px' height='32px' viewBox='0 0 2048 2048' style='enable-background:new 0 0 2048 2048;' xml:space='preserve'%3E %3Cpath d='M1299 1088q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23z'/%3E %3C/svg%3E"), pointer;
  }

  .Navigate.decorationleft{
    transform: rotate(180deg);
    cursor: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='32px' height='32px' viewBox='0 0 2048 2048' style='transform:rotate(180deg);enable-background:new 0 0 2048 2048;' xml:space='preserve'%3E %3Cpath d='M1299 1088q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23z'/%3E %3C/svg%3E"), pointer;
  }

  .RenderHelper {
    height: 100vh;
    grid-template-columns: 70% 30%;
    grid-template-rows: unset;
    grid-template-areas:  "gallery text"
                          "gallery text";
  }

  .RenderHelper-image .RenderHelper-container img, .RenderHelper-container {
    
  }

  .RenderHelper-image {
    width: 100%;
    height: auto;
  }

  .RenderHelper-content.vertical {
    max-width: 70%;
  }
}

