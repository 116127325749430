@import url('https://fonts.googleapis.com/css?family=Lekton');

@font-face {
  font-family: 'whitney_book';
  src: local('whitney_book'), url(./fonts/whitney_book.ttf) format('truetype');
}


@font-face {
  font-family: 'whitney_bold';
  src: local('whitney_bold'), url(./fonts/whitney_bold.ttf) format('truetype');
}


body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'whitney_book', sans-serif !important;
}
